<template>
	<div id="terms">
		<div id="terms-inner">
			<h2>Terms & Conditions</h2>
			<p>
				We appreciate you choosing CustomBrandingPRO for your graphic
				design requirements.
			</p>
			<p>
				Before proceeding, take a moment to carefully review this
				agreement—it's important!
			</p>
			<p>
				<strong>Our Services:</strong> We're here to provide
				comprehensive graphic design services, covering everything from
				Brand Design to Logo Refreshing and PowerPoint presentation
				"clean-ups." We've got your graphic design needs sorted!
			</p>
			<p>
				<strong>Payment Process:</strong> To get started, complete our
				Briefing Sheet. A heads up, if you go back to it before
				finalizing the payment, you'll need to start over. Additionally,
				tasks outside the design scope, such as IT, domain purchases,
				printing, or material costs, may result in additional charges.
			</p>
			<p>
				<strong>Next Steps:</strong> Received your Three Brand Concepts
				and want more? Easy! Reply to our email, state your preferred
				package, and we'll send you an invoice for a minimum 50% upfront
				fee. The Basic Artwork Package at 390€ is invoiced in full
				before we kick off the task.
			</p>
			<p>
				<strong>Legal Details:</strong> By agreeing, you commit to
				following universal copyright laws. Please note that
				CustomBrandingPRO and Nina A. Ignatius won't handle licenses or
				usage rights for fonts, images, logos, or any other elements.
				Additionally, refrain from selling our designs to third parties,
				even if you choose not to use them. Your consent also allows
				CustomBrandingPRO to potentially use your designs and logos for
				marketing purposes.
			</p>
			<p>
				<strong>Confirmation:</strong> Tick the box to confirm that
				you've read and understood the terms and conditions.
			</p>
			<p>
				<em
					>PLEASE NOTE THAT ONCE YOU CONFIRM AND ACCEPT THE TERMS AND
					CONDITIONS YOU WILL BE DIRECTED TO THE PAY PAGE. RETURNING
					TO THIS PAGE WITH YOUR FILLED IN FORMULA WILL NOT BE
					POSSIBLE AFTER THAT AND WILL NULLIFY YOUR ORDER, AND YOU
					WOULD NEED TO START OVER AGAIN AS THE PAYMENT WILL NOT GO
					THROUGH.</em
				>
			</p>
			<p>
				<strong>Questions or Concerns:</strong> If you have any
				questions, feel free to contact us at
				<a href="mailto:nina@custombrandingpro.com"
					>nina@custombrandingpro.com</a
				>.
			</p>
			<p>Thank you for selecting CustomBrandingPRO!</p>
			<p>
				CustomBrandingPRO is a service offered by Beibamboo Oy/Ltd, VAT
				number FI22833580. We appreciate your understanding and
				cooperation.
			</p>
			<p>Best regards, The CustomBrandingPRO Team</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Terms'
	};
</script>

<style scoped>
	#terms {
		margin: 0 auto;
		margin-top: 10vh;
		max-width: 80%;
		padding: 10px;
	}

	#terms-inner {
		padding-top: 80px;
	}

	#terms h2,
	p {
		max-width: 100%;
		text-align: center;
		padding: 5px;
	}
</style>
